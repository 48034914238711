import React from "react";
import { AppSession, ISessionResult } from "./AppSession";
import { Broadcast } from "./Broadcast";
import {PageContainer} from "./PageContainer";
import { IUpdateSpinnerState, SpinnerManager } from "./SpinnerManager";
import { ICanvasError, IPageLaunchParams, ISigninSettings,IStudioSettings } from "./types";
import { Url } from "./Url";
import { IComponentDefinition } from "./ComponentDefinition";
import { RecordMeta } from "./RecordMeta";
import { Canvas } from "core";
import { ContextMenuManager } from "./ContextMenuHandler";
import { HoverPopupManager } from "./HoverPopupHandler";
import { CanvasSettingsCache } from "./CanvasSettingsCache";
import { MenuManager } from "./MenuHandler";
import {TabSetState} from './TabState';


export type ISigninUrlValues = {token?:string,tokenType?:string,username?:string};
export type ISigninProps = {app:Application,done:(result:ISessionResult)=> void,urlValues:ISigninUrlValues};

export type DesignMode = 'UI' | 'Code' | 'FieldEdit';


class CartManager {
    broadcast:Broadcast = new Broadcast();
    appData:any;

    constructor(app:Application){
        this.appData = app.appData;
    }

    get cart_id():string {
        return this.appData["cart_id"];
    }
    set cart_id(value:string){
        this.appData["cart_id"] = value;
        this.broadcast.dispatch("changed");
    }

    get cart_name():string {
        return this.appData["cart_name"]
    }
    set cart_name(value:string){
        this.appData["cart_name"] = value;
    }

    get cart_item_count():number {
        return this.appData["cart_item_count"]
    }
    set cart_item_count(value:number){
        this.appData["cart_item_count"] = value;
    }

    get cart_branch_name():string {
        return this.appData["cart_branch_name"]
    }

    set cart_branch_name(value:string){
        this.appData["cart_branch_name"] = value;
    }

    connect(listener:React.Component<{},{}>,callback:()=> void){
        this.broadcast.connect(listener,"*",(action,data) => callback());
    }

    disconnect(listener:React.Component<{},{}>){
        this.broadcast.disconnect(listener);
    }

}

export class AppDataRecord {
    record:any;

    $$meta:RecordMeta;
    
    constructor(record:any){
        this.record = record;
        this.$$meta = new RecordMeta();
        this.$$meta.readonly = true;
    }
    $$get(name:string){
        return this.record[name];
    }
    $$set(name:string,value:any){
        this.record[name] = value;
    }
}

export class Application {
    notifications:Broadcast = new Broadcast();
    pageNavigate:Broadcast = new Broadcast();

    updateSpinnerState:IUpdateSpinnerState;
    spinner:SpinnerManager = new SpinnerManager(this);
    getMessageBox:() => React.ComponentType<{message:string,style:string}>;
    getConfirmDialog:() => React.ComponentType<{message:string,style:string}>;
    doPostRequest:(path:string,body:any) => Promise<any>;
    renderError:(canvas:Canvas,error:ICanvasError) => any;
    renderSignin:(props:ISigninProps) => React.ReactElement;
    static components:{[name:string]:React.ComponentClass} = {};
   
    static instance:Application;
    static screens:{[name:string]:React.ComponentType} = {};

    studioWindow:Window;
    host:string;
    activeScreens:PageContainer[] = [];
    domain:string;
    branch:string;
    appName:string;
    studioOpen:boolean;
    session:AppSession;
    signinSettings: ISigninSettings;
    studioSettings:IStudioSettings;
    studioBroadcast:Broadcast = new Broadcast();
    studioContent:IPageLaunchParams;
    studioContentKey:number;
    designerToolboxBroadcast:Broadcast = new Broadcast();
    version:string;
    portalTitle:string;
    showOverlay:boolean;
    overlayBroadcast:Broadcast = new Broadcast();
    selectedBlock:string;

    codeDesignerEnabled:boolean;
    designerTargetPage:string;
    codeDesignBroadcast:Broadcast = new Broadcast();

    workspaceKey = 1;
    currentWorkspace:string;
    workspaceContentKey = 1;
    currentWorkspaceLink:string;
    currentWorkspaceContent:IPageLaunchParams;
    appData:any;
    cart:CartManager;
    canvasSettingsCache:CanvasSettingsCache;
   
    contextMenuManager:ContextMenuManager = new ContextMenuManager();
    hoverPopupManager:HoverPopupManager = new HoverPopupManager();
    menuManager:MenuManager = new MenuManager();

    workspaceTabState:TabSetState;
    currentDraftView:{[pageName:string]:string} = {};
    
    constructor(){
        this.session = new AppSession(this);
        this.appData = {};
        this.cart = new CartManager(this);
        this.canvasSettingsCache = new CanvasSettingsCache(this);

    }

    registerScreen(screen:PageContainer){
        this.activeScreens.push(screen);
    }

    unregisterScreen(screen:PageContainer){
        let i = this.activeScreens.indexOf(screen);
        if (i != -1){
            this.activeScreens.splice(i,1);
        }
    }

    reloadScreen(screenName:string){
        for(let i = 0; i < this.activeScreens.length;i++){
            let activeScreen = this.activeScreens[i];
            if (activeScreen.canvas.launchParams.name == screenName){
                activeScreen.reload();
            }
        }
    }

    findScreen(screenName:string):PageContainer{
        for(let i = 0; i < this.activeScreens.length;i++){
            let activeScreen = this.activeScreens[i];
            if (activeScreen.canvas.launchParams.name == screenName){
                return activeScreen;
            }
        }
        return null;
    }

    static addComponents(namespace:string,manifest:any){
        if (manifest){
            for(let key in manifest){
                if (namespace){
                    Application.components[namespace + "." + key] = manifest[key];
                }
                else {
                    Application.components[key] = manifest[key];
                }
            }
        }
    }
    static getKind(name:string):any{
        return Application.components[name];
    }

    static getComponentDef(name:string):IComponentDefinition {
        let c = Application.components[name];
        if (c){
            return (c as any).$def as IComponentDefinition;
        }
        return null;
    }

    getImageUrl(filename:string): string {
        if (!filename) return null;
        if (filename.indexOf("//") != -1 || filename[0] == "/"){
            return filename;
        }
        return this.qualifyUri("content/" + filename);
    }

    qualifyUri(path:string):string {
        let host = this.host || "";
        host += "/" + this.appName + "/";
        if (!path){
            return host;
        }
        if (path[0] == '/'){
            return host + path.substr(1);
        }
        return host + path;
    }

  
    setWorkspaceScreen(workspace:string,link:string,options:{pushState?:boolean,replaceState?:boolean,page?:IPageLaunchParams}){
      //  this.workspaceContentKey++;
        document.title = ""; // this.bannerSettings.windowTitle;
        this.currentWorkspace = workspace;

        this.currentWorkspaceLink = link;
        
        let page = options.page;
        if (!page){
            this.currentWorkspaceContent = null;
            if (options.replaceState || options.pushState){
                this.setWorkspaceUrl(options.replaceState);
            }
        }
        else {
            this.currentWorkspaceContent = page;
        }
    }

    setWorkspaceUrl(asReplace:boolean){
        let folder:string;
        let workspace = this.currentWorkspace;
        if (workspace){
            let i = workspace.lastIndexOf('/');
            if (i != -1){
                folder = workspace.substring(i + 1);
            }
        }
        
        let url = Url.stringify(this,folder,null);
        if (asReplace){
            window.history.replaceState(null,null,url);
        }
        else {
            window.history.pushState(null,null,url);
        }
    }

    openWorkspace(workspace:string,options?:{page?:IPageLaunchParams}){
        this.workspaceKey++;
        options = options || {};
        this.setWorkspaceScreen(workspace,null,{pushState:true,page:options.page});
        this.pageNavigate.refresh();
    }

    isWorkspaceContent(link:string):boolean{
        if (this.currentWorkspaceLink && this.currentWorkspaceLink == link){
           return true;
        }
    }

    executeCanvasPollCycle(){
        for(let key in this.activeScreens){
            let canvas = this.activeScreens[key].canvas;
            if (canvas.autoRefreshSettings){
                canvas.autoRefreshSettings.refreshIfStale();
            }
        }
    }

    pollingTimer:any;

    startPolling(){
        let frequency = 10 * 1000; // 10 seconds
        this.pollingTimer = setInterval(()=> {
            Application.instance.executeCanvasPollCycle()
        },frequency);
        Application.instance.executeCanvasPollCycle();
    }

    stopPolling(){
        clearInterval(this.pollingTimer);
        this.pollingTimer = null;
    }

    openStudio(page:IPageLaunchParams){
        this.studioContent = page;
        this.studioContentKey++;
        this.studioOpen = true;
        this.studioBroadcast.refresh();
    }

    closeStudio(){
        this.studioOpen = false;
        this.studioBroadcast.refresh();
    }
    
}

Application.instance = new Application();

